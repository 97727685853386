import { Button, Switch, Grid, Typography } from '@mui/material'
import React from 'react'
import Layout from '../Components/Layout'
import { appName } from '../Constants/const'
import nutDataDump from "../data.json";

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import { Tooltip as MuiToolTip } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import moment from 'moment';

import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, Legend, YAxis, Tooltip } from 'recharts';

const nutData = nutDataDump.data;
const nutDataUpdated = nutDataDump.updated;

function Timechart() {
  const [oldestDataDate, setOldestDataDate] = React.useState(new Date(Math.min(...nutData.map(d => new Date(d.date))))); // eslint-disable-line no-unused-vars
  const [newestDataDate, setNewestDataDate] = React.useState(new Date(Math.max(...nutData.map(d => new Date(d.date))))); // eslint-disable-line no-unused-vars
  const [showPartner, setShowPartner] = React.useState(true);
  // const [startDate, setStartDate] = React.useState(new Date(new Date().setDate(newestDataDate.getDate() - 7)));
  const [startDate, setStartDate] = React.useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  // const [endDate, setEndDate] = React.useState(newestDataDate);
  const [endDate, setEndDate] = React.useState(new Date());

  const getFilteredData = (startDate, endDate) => {
    return nutData.filter(d => new Date(d.date) >= startDate && new Date(d.date) <= endDate);
  }

  const getDataSolo = (data) => { // eslint-disable-line no-unused-vars
    return data.filter(d => d.type === "Solo");
  }

  const getDataPartner = (data) => { // eslint-disable-line no-unused-vars
    return data.filter(d => d.type === "Partner");
  };

  const getCountSolo = (data) => {
    return data.filter(d => d.type === "Solo").length;
  };

  const getCountPartner = (data) => {
    return data.filter(d => d.type === "Partner").length;
  };

  const getCountPartnerExternal = (data) => {
    return data.filter(d => d.type === "Partner" && !d.penetrative).length;
  };

  const getCountPartnerInternal = (data) => {
    return data.filter(d => d.type === "Partner" && d.penetrative).length;
  };

  const getNumDays = (start, end) => {
    return (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
  }

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const getDailyCounts = (data) => {
    const dailyCounts = [];
    let currentDate = new Date(new Date(startDate).setHours(0,0,0,0));
    while (currentDate <= endDate) {
      let iDate = new Date(currentDate)
      const dailyCount = data.filter(d => datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      const dailyCountSolo = data.filter(d => d.type === "Solo" && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      const dailyCountPartner = data.filter(d => d.type === "Partner" && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      const dailyCountPartnerExternal = data.filter(d => d.type === "Partner" && !d.penetrative && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      const dailyCountPartnerInternal = data.filter(d => d.type === "Partner" && d.penetrative && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      dailyCounts.push({ date: iDate, count: dailyCount, countSolo: dailyCountSolo, countPartner: dailyCountPartner, countWithPartner: dailyCountPartnerExternal, countInPartner: dailyCountPartnerInternal });
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dailyCounts;
  }

  const getDailyCountsSolo = (data) => { // eslint-disable-line no-unused-vars
    const dailyCounts = [];
    let currentDate = new Date(new Date(startDate).setHours(0,0,0,0));
    while (currentDate <= endDate) {
      let iDate = new Date(currentDate)
      const dailyCountSolo = data.filter(d => d.type === "Solo" && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      dailyCounts.push({ date: iDate, count: dailyCountSolo, category: "Solo" });
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dailyCounts;
  }

  const getDailyCountsPartner = (data) => { // eslint-disable-line no-unused-vars
    const dailyCounts = [];
    let currentDate = new Date(new Date(startDate).setHours(0,0,0,0));
    while (currentDate <= endDate) {
      let iDate = new Date(currentDate)
      const dailyCountPartner = data.filter(d => d.type === "Partner" && datesAreOnSameDay(new Date(d.date), new Date(currentDate))).length; // eslint-disable-line no-loop-func
      dailyCounts.push({ date: iDate, count: dailyCountPartner, category: "Partner" });
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dailyCounts;
  }

  const formatXAxis = (tickItem) => { // do something to format tick label }
    return moment(tickItem).format('D MMM');
  }

  const formatLabel = (tickItem) => { // do something to format tick label }
    return moment(tickItem).format('YYYY-MM-DD');
  }

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }

  const getHorniestDay = (data) => {
    let daily = getDailyCounts(data);
    let max = Math.max(...daily.map(d => d.count));
    let days = daily.filter(d => d.count === max);
    return { max: max, days: days };
  }

  const handleResetDates = () => {
    // setStartDate(new Date(new Date().setDate(newestDataDate.getDate() - 7)));
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)));
    // setEndDate(newestDataDate);
    setEndDate(new Date());
  }

  const mostRecentNut = (data) => {
    let mostRecent = data[data.length-1];
    return mostRecent;
  }

  const mostRecentNutDate = (data) => {
    let mostRecent = mostRecentNut(data);
    let mostRecentDate = new Date(mostRecent.last_update_local);
    return mostRecentDate;
  }

  const mostRecentNutFormatted = (data) => {
    return moment(data).fromNow();
  }
  
  return (
    <Layout>
      <div>
        <a href="https://nuttrackr.com/u/OddPawsX">
          <Typography variant="h2">This page is deprecated; please use the new nutTRACKR website!</Typography>
        </a>
        <Typography variant="h2">{appName} - Timechart</Typography>
        {/* <Typography variant="body1" gutterBottom>
          {appName} is a web application built to display statistics about Astra's... habits.
        </Typography> */}

        <Box>
          <Typography variant="overline" gutterBottom>
            {`Data Set Updated: ${new Date(nutDataUpdated*1000)}`}
          </Typography>
        </Box>

        <Box>
          <MuiToolTip title={mostRecentNutDate(nutData).toLocaleString()}>
            <Typography variant="caption" gutterBottom>
              {`Most recent event: ${mostRecentNutFormatted(mostRecentNutDate(nutData))} (${mostRecentNut(nutData).type === "Partner" ? (mostRecentNut(nutData).penetrative ? "In Partner" : "With Partner") : mostRecentNut(nutData).type})`}
            </Typography>
          </MuiToolTip>
        </Box>

        <div id="vis" style={{ marginTop: "2em", marginBottom: "2em" }}>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              // width={750} height={500}
              data={getDailyCounts(getFilteredData(startDate, endDate))}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <CartesianGrid strokeDasharray="5 5" />
              {/* <Line type="monotone" dataKey="countSolo" stroke="#8884d8" />
              <Line type="monotone" dataKey="countPartner" stroke="#82ca9d" /> */}
              <XAxis dataKey="date" domain={[startDate, endDate]} tickFormatter={formatXAxis}/>
              <YAxis dataKey="count" />
              <Tooltip
                labelStyle={{ color: "black" }}
                labelFormatter={formatLabel}
              />
              <Legend />
              <Line dataKey="countSolo" stroke={"#1BBEF1"} name="Solo" domain={[startDate, endDate]} tickFormatter={formatXAxis} />
              {/* {showPartner ? <Line dataKey="countPartner" stroke={"#C21BF1"}  name="With Partner" domain={[startDate, endDate]} tickFormatter={formatXAxis} /> : ""} */}
              {showPartner ? <Line dataKey="countWithPartner" stroke={"#55d973"}  name="With Partner" domain={[startDate, endDate]} tickFormatter={formatXAxis} /> : ""}
              {showPartner ? <Line dataKey="countInPartner" stroke={"#C21BF1"}  name="In Partner" domain={[startDate, endDate]} tickFormatter={formatXAxis} /> : ""}
            </LineChart>
          </ResponsiveContainer>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <FormGroup>
                <FormControlLabel control={
                   <Switch
                   checked={showPartner}
                   onChange={() => {setShowPartner(!showPartner)}}
                   inputProps={{ 'aria-label': 'Toggle "With Partner" on Graph' }}
                 />
                } label='Toggle "With Partner" on Graph' />
               
              </FormGroup>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={5} alignItems="center" justifyContent="center">
          <Grid item xs={6}>
          <div id="summary-data">
          <Typography variant="h4">Selected Time Span Totals</Typography>
          <Typography variant="overline" gutterBottom>{`Spans ${Math.trunc(getNumDays(startDate, endDate))} days`}</Typography>
          <Typography variant="body1" gutterBottom>
            {startDate instanceof Date ? startDate.toLocaleDateString() : ""} - {endDate instanceof Date ? endDate.toLocaleDateString() : ""}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`Solo Count: ${getCountSolo(getFilteredData(startDate, endDate))}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`With Partner Count: ${getCountPartner(getFilteredData(startDate, endDate))}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {` |--> *With* Partner Count: ${getCountPartnerExternal(getFilteredData(startDate, endDate))}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {` |--> *In* Partner Count: ${getCountPartnerInternal(getFilteredData(startDate, endDate))}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`Busiest Day (Solo): ${getHorniestDay(getDataSolo(getFilteredData(startDate, endDate))).max} times on ${getHorniestDay(getDataSolo(getFilteredData(startDate, endDate))).days.map(d => d.date.toLocaleDateString()).join(", ")}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`Busiest Day (With Partner): ${getHorniestDay(getDataPartner(getFilteredData(startDate, endDate))).max > 1 ? (
              `${getHorniestDay(getDataPartner(getFilteredData(startDate, endDate))).max} times on ${getHorniestDay(getDataPartner(getFilteredData(startDate, endDate))).days.map(d => d.date.toLocaleDateString()).join(", ")}`
            ) : (
              "n/a"
            )}`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`Busiest Day (Combined): ${getHorniestDay(getFilteredData(startDate, endDate)).max > 1 ? (
              `${getHorniestDay(getFilteredData(startDate, endDate)).max} times on ${getHorniestDay(getFilteredData(startDate, endDate)).days.map(d => d.date.toLocaleDateString()).join(", ")}`
            ) : (
              "n/a"
            )}`}
          </Typography>
        </div>
          </Grid>
          <Grid item xs={6}>
          <div id="controls" style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Box sx={{marginBottom: "1em"}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                minDate={oldestDataDate}
                // maxDate={newestDataDate}
                maxDate={new Date()}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextFieldComponent {...params} />}
                showTodayButton
              />
            </LocalizationProvider>
            <span> to </span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                minDate={oldestDataDate}
                // maxDate={newestDataDate}
                maxDate={new Date()}
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextFieldComponent {...params} />}
                showTodayButton
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{marginBottom: "1em"}}>
            <Button variant="contained" color="primary" onClick={handleResetDates}>Reset Dates</Button>
          </Box>
        </div>
          </Grid>
        </Grid>
        

      </div>
      <div style={{ marginBottom: "5em" }} />
    </Layout>
  )
}

export default Timechart