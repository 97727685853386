import { Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../Components/Layout'
import { appName } from '../Constants/const'

function Home() {
  return (
    <Layout>
      <div>
        <Typography variant="h1">{appName}</Typography>
        <Typography variant="body1" gutterBottom>
          {appName} is a web application built to display statistics about Astra's... habits.
        </Typography>
        {/* <Button variant="contained" size="large" color="primary" component={Link} to="/nut">
          Continue (18+)
        </Button> */}
        <Button variant="contained" size="large" color="primary" component="a" href="https://nuttrackr.com/u/OddPawsX">
          Continue (18+)
        </Button>
      </div>
    </Layout>
  )
}

export default Home;