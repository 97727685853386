import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';

// https://zenoo.github.io/mui-theme-creator/
const themeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

export const theme = createTheme(themeOptions);

export const NutTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>
    <React.Fragment>
      {children}
    </React.Fragment>
  </ThemeProvider>
}
